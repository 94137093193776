import React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  BreadCrumb,
  Container,
  Section,
  MarkdownBody,
  MarkdownContent,
} from "../components/Section"
import {
  HeroContainer,
  Hero,
  Background,
  HeroCaption,
  HeroTitle,
  HeroDescription,
} from "../components/Hero"

const StaticPage = ({ location }) => (
  <Layout isHeader={true} location={location}>
    <Seo
      title="Terms of Uses - Liberty Metal Structures"
      description="Metal Building Structures' terms of uses are designed to help you understand all the terms of uses of the website and information available on it."
    />
    <Section
      xpt="114px"
      mpt="94px"
      pt="64px"
      xpb="0"
      mpb="0"
      pb="0"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <HeroContainer>
          <Hero>
            <Background>
              <StaticImage
                src="../images/terms-use-banner-bg.jpg"
                placeholder="blurred"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="terms-use-banner-bg"
              />
            </Background>
            <HeroCaption>
              <BreadCrumb className="white" position="static" translate="0">
                <Link to="/">Home</Link>
                <span>Terms of Use</span>
              </BreadCrumb>
              <HeroTitle>Terms of Use</HeroTitle>
              <HeroDescription maxWidth="1120px">
                <p>
                  Please read these Terms and Conditions (“Terms”, “Terms and
                  Conditions”) carefully before using the
                  www.libertymetalstructures.com website operated by Liberty
                  Metal Structures (“us”, “we”, or “our”).
                </p>
                <p>
                  Your access to and use of the Service is conditioned on your
                  acceptance of and compliance with these Terms. These Terms
                  apply to all visitors, users, and others who access or use the
                  Service.
                </p>
                <p>
                  By accessing or using the Service you agree to be bound by
                  these Terms. If you disagree with any part of the terms, then
                  you may not access the Service.
                </p>
              </HeroDescription>
            </HeroCaption>
          </Hero>
        </HeroContainer>
      </Container>
    </Section>
    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <h2>Terms and Conditions from Liberty Metal Structures</h2>
            <h3>Accounts</h3>
            <p>
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your
              password, whether your password is with our Service or a
              third-party service.
            </p>
            <p>
              You agree not to disclose your password to any third party. You
              must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </p>

            <h3>Links to Other Websites</h3>
            <p>
              Our Service may contain links to third-party websites or services
              that are not owned or controlled by Liberty Metal Structures.
            </p>
            <p>
              Liberty Metal Structures has no control over, and assumes no
              responsibility for, the content, privacy policies, or practices of
              any third-party websites or services. You further acknowledge and
              agree that Liberty Metal Structures shall not be responsible or
              liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with use of or reliance
              on any such content, goods or services available on or through any
              such websites or services.
            </p>
            <p>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.
            </p>

            <h3>Termination</h3>
            <p>
              We may terminate or suspend access to our Service immediately,
              without prior notice or liability, for any reason whatsoever,
              including without limitation if you breach the Terms.
            </p>
            <p>
              All provisions of the Terms which by their nature should survive
              termination, including, without limitation, ownership provisions,
              warranty disclaimers, indemnity, and limitations of liability.
            </p>
            <p>
              Upon termination, your right to use the Service will immediately
              cease. If you wish to terminate your account, you may simply
              discontinue using the Service.
            </p>

            <h3>Governing Law</h3>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of the United States, without regard to its conflict of law
              provisions.
            </p>
            <p>
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service and supersede and replace any prior agreements we might
              have between us regarding the Service.
            </p>

            <h3>Changes</h3>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material, we will try to
              provide at least 15 days notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>
            <p>
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, please stop using the Service.
            </p>

            <h3>Contact Us</h3>
            <p>
              If you have any questions about these Terms, please contact us.
            </p>

            <h3>Liberty Metal Structures</h3>
            <p>
              <a href="mailto:sales@libertymetalstructures.com">
                sales@libertymetalstructures.com
              </a>
              <br />
              796 W Lebanon St. Mount Airy, NC 27030
              <br />
              <a href="tel:8004407309">(800) 440-7309</a>
            </p>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
  </Layout>
)

export default StaticPage
