import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'

export const Background = styled.div`
  width:100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity:0.08;
  .gatsby-image-wrapper{
    height: 100%;
    display:block;
  }
`
export const Hero = styled.div`	
	position: relative;
    padding-top:40px;
    padding-bottom:40px;
	${BreakpointUp.xl`
        padding-top:60px;
        padding-bottom:60px;
	`}
	${BreakpointUp.xxl`
		padding-top: 100px;
		padding-bottom:100px; 
	`}	

    &:after, &:before {
        display:${props => (props.bgAfterBefore ? 'none' : 'block')};
        top: calc(100% - 1px);
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index:1;
    }


    &:after {
        border-color: rgba(0, 0, 0, 0);
        border-top-color: #033366;
        border-width: 10px;
        margin-left: -10px;
        ${BreakpointUp.sm`
            border-width: 15px;
            margin-left: -15px;
        `}
    }
    &:before {
        border-color: rgba(3, 51, 102, 0);
        border-top-color: #033366;
        border-width: 15px;
        margin-left: -15px;
        ${BreakpointUp.sm`
            border-width: 20px;
            margin-left: -20px;
        `}
    }
`
export const HeroStatic = styled.div`	
	position: relative;
    padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};

	${BreakpointUp.xl`
		padding-top: ${props=>props.mpt};
		padding-bottom: ${props=>props.mpb};	
	`}
	${BreakpointUp.xxl`
		padding-top: ${props=>props.xpt};
		padding-bottom: ${props=>props.xpb};	
	`}
`
export const HeroCaption = styled.div`
	position:relative;
	z-index:2;
	text-align:center;
	color:#fff;
    width:100%;
    padding: 0 15px;
    ${BreakpointUp.sm`
	    padding: 0 30px;
    `}   
`
export const HeroTitle = styled.h1`
	width:100%;
    margin:5px auto 20px auto;
	color:${props=>props.color};
    max-width:${props=>props.maxWidth};
`
HeroTitle.defaultProps = {
	color: "#fff"
}

export const HeroDescription = styled.div`
	max-width:${props=>props.maxWidth};
	width:100%;
	margin:0 auto;
    color:${props=>props.color};
	> p{
		margin-bottom:0;
		+ p{
			margin-top:30px;
		}
	}
    a{
        color:#fff;
        font-weight: 700;
        &:hover, &:focus{
            color:#D51333;
        }            
    }

`
HeroDescription.defaultProps = {
	color: "rgba(255,255,255,0.6)"
}

export const HeroContainer = styled.div`
    background-color: ${props=>props.bgColor};	    
`
HeroContainer.defaultProps = {
	bgColor: "#033366"
}

export const HeroVideo = styled.div`
    position: relative;
    z-index: 2;
    max-width:1192px;
    width:100%;
    margin:20px auto 0 auto;    
    padding:0 15px;
    ${BreakpointUp.sm`
        margin:40px auto 0 auto;
        padding:0 30px;
    `}
`
export const HeroMedia = styled.div`
    position: relative;
    max-width:1192px;
    width:100%;
    margin:30px auto 0 auto;
    .gatsby-image-wrapper {
        width: 100%;
        display: block;
        border-radius:4px;
        box-shadow:0 20px 65px rgba(3,51,102,0.15);
    }
`